import React, { useContext } from "react"

import Wrapper from "../shared/wrapper"
import ZiptiText from "../shared/zipti_text"
import ConversationList from "../shared/conversation_list"
import ProfileInfoCard from "../shared/profile_info_card"
import { UserContext } from "../user_wrapper"

const UserHome = props => {
  const user = useContext(UserContext)

  const conversations = [
    {
      profileName: "Yvette Young",
      timeSince: "52 minutes ago",
      notifications: 3,
    },
    {
      profileName: "Massimo Bottura",
      timeSince: "3 days ago",
      notifications: 0,
    },
    {
      profileName: "Tan France",
      timeSince: "5 days ago",
      notifications: 1,
    },
  ]

  return (
    <Wrapper>
      <ProfileInfoCard
        name="Jill Anderson"
        subtitle="bluejay22@gmail.com"
        linkCopy="Edit Account Info"
        linkTo="/edit_user_profile"
      />
      <ZiptiText
        mt="3vh"
        fontSize="2.5vh"
        fontWeight="bold"
        onClick={_ => {
          alert(JSON.stringify(user))
        }}
      >
        Conversations ({conversations?.length})
      </ZiptiText>
      <ConversationList conversations={conversations} />
    </Wrapper>
  )
}

export default UserHome
